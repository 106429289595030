<template>
  <div>
    <!--
      Teams datatable
    -->
    <CyDataTableYdApi
      ref="cyDataTable"
      :fetch-available="{ keyPath: 'teams' }"
      :bulk="hasBulkModeEnabled"
      :headers="$static.headers"
      :link-builder="getLinkTarget"
      :searchable-fields="$static.searchableFields"
      key-field="canonical">
      <template #table-cmp-header-actions="{ selected }">
        <template v-if="!_.isEmpty(selected)">
          <CyDevBtn
            class="my-0 mr-2"
            @click.native="setMissingOwners(selected)">
            Set missing owner
          </CyDevBtn>

          <CyButton
            theme="error"
            icon="delete"
            @click="openDeleteModal(selected)">
            {{ $tc('deleteTeamBtn', selected.length, { nb: selected.length }) }}
          </CyButton>
        </template>

        <CyButton
          v-else
          v-has-rights-to="'CreateTeam'"
          icon="add"
          :to="{ name: 'newTeam' }"
          @click="$gtm.trackEvent($static.gtmOrganizationsEvents.organizationsCreateNewTeam)">
          {{ $t('createTeamBtn') }}
        </CyButton>

        <!-- Delete modal -->
        <CyModal
          v-if="showDeleteModal"
          :header-title="$tc('confirmDeleteTitle', toDelete.length)"
          :action-btn-func="onDelete"
          :action-btn-text="$tc('deleteTeamBtn', selected.length, { nb: selected.length })"
          :cancel-btn-func="closeDeleteModal"
          modal-type="delete">
          <p>
            {{ $t('forms.cannotBeUndone') }}
            <span v-html="$tc('areYouSure', toDelete.length, { item: toDelete[0].name })"/>
          </p>
          <ul class="items-to-delete">
            <li
              v-for="{ canonical, name } in toDelete"
              :key="canonical">
              <b>{{ name }}</b>
            </li>
          </ul>
        </CyModal>
      </template>

      <template #table-cmp-body-row="{ props: { item } }">
        <td class="text-left">
          {{ item.name }}
        </td>

        <td class="text-left">
          {{ getRolesNamesList(item.roles) }}
        </td>

        <td>
          <CyFormsAssignOwner
            v-if="canUpdateOwner(item)"
            :errors="errors"
            :form-content="item"
            :action-btn-func="assignNewOwner"
            sm/>
          <CyButton
            v-else
            :to="{
              name: 'member',
              params: {
                id: String(item.owner.id),
                backRouteTo: 'teams',
              },
            }"
            :disabled="!canGoToMember(item)"
            :readonly="!canGoToMember(item)"
            theme="grey"
            variant="tertiary"
            sm
            member-link
            @click.stop>
            <CyMember
              :member="item.owner"
              simple
              sm/>
          </CyButton>
        </td>

        <td class="text-left">
          <div class="member__container">
            <span class="member__count">{{ item.member_count }}</span>
            <CyTooltip
              v-for="member of item.members_preview"
              :key="`member-${member.id}`"
              bottom>
              <template #activator="{ on }">
                <CyButton
                  :to="{
                    name: 'member',
                    params: {
                      id: String(member.id),
                      backRouteTo: 'teams',
                    },
                  }"
                  :disabled="!canGoToMember(member)"
                  :readonly="!canGoToMember(member)"
                  theme="grey"
                  variant="tertiary"
                  light
                  class="mr-1"
                  member-link="mini"
                  @click.stop
                  v-on="on">
                  <CyMember
                    :member="member"
                    avatar-only
                    sm/>
                </CyButton>
              </template>
              <span>{{ displayName(member) }}</span>
            </CyTooltip>
            <span
              v-if="item.member_count > item.members_preview.length"
              class="text-truncate">
              ...
            </span>
          </div>
        </td>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CyDataTableYdApi from '@/components/data-table-yd-api.vue'
import { constructBreadcrumb, displayName, getMissingOwnerObject, hasNoOwner } from '@/utils/helpers'
import { gtmOrganizationsEvents } from '@/utils/helpers/analytics'

export default {
  name: 'CyPageTeams',
  components: {
    CyDataTableYdApi,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.teams'))
  },
  data: () => ({
    showDeleteModal: false,
    toDelete: [],
  }),
  computed: {
    ...mapState('organization', {
      teams: (state) => state.available.teams,
    }),
    ...mapState('organization/team', {
      errors: (state) => state.errors.team,
    }),
    $static () {
      return {
        gtmOrganizationsEvents,
        headers: [
          {
            text: this.$t('name'),
            align: 'left',
            value: 'name',
          },
          {
            text: this.$t('defaultRoles'),
            align: 'left',
            value: 'roles',
          },
          {
            text: this.$t('owner'),
            align: 'left',
            value: 'owner',
            sort: (a, b) => displayName(a).localeCompare(displayName(b)),
          },
          {
            text: this.$t('Members'),
            align: 'left',
            value: 'member_count',
          },
        ],
        searchableFields: [
          {
            name: 'name',
            label: this.$t('name'),
          },
          {
            name: 'roles',
            label: this.$t('defaultRoles'),
            filterFunction: this.getRolesNamesList,
          },
          {
            name: 'owner',
            label: this.$t('owner'),
            filterFunction: this.displayName,
          },
          {
            name: 'members_preview',
            label: this.$t('member'),
            filterFunction: this.getMembersList,
          },
        ],
      }
    },
    hasBulkModeEnabled () {
      const canDelete = this.$cycloid.permissions.canDisplay('DeleteTeam')
      return canDelete && !_.isEmpty(this.teams)
    },
  },
  methods: {
    displayName,
    ...mapActions('organization', [
      'BULK_DELETE',
    ]),
    ...mapActions('organization/team', [
      'UPDATE_TEAM',
    ]),
    async assignNewOwner ({ owner, formContent }) {
      const team = {
        name: formContent.name,
        owner: owner?.username,
        canonical: formContent.canonical,
        roles_canonical: _.map(formContent.roles, 'canonical'),
      }
      const successMessage = this.$t('alerts.success.team.reassigned', {
        teamName: formContent.name,
        owner: displayName(owner),
      })
      await this.UPDATE_TEAM({ team, successMessage })
      if (_.isEmpty(this.errors)) this.$refs.cyDataTable.retrieveItems({ clearErrors: false })
    },
    openDeleteModal (toDelete) {
      this.toDelete = toDelete
      this.$toggle.showDeleteModal(true)
    },
    closeDeleteModal () {
      this.$resetData('toDelete')
      this.$toggle.showDeleteModal(false)
    },
    getRolesNamesList (roles) {
      return roles.reduce((acc, current) => acc
        ? `${acc}, ${current.name}`
        : `${current.name}`, '',
      )
    },
    getMembersList (members) {
      return members.reduce((acc, current) => acc
        ? `${acc}, ${current.given_name} ${current.family_name}`
        : `${current.given_name} ${current.family_name}`, '',
      )
    },
    getLinkTarget ({ canonical: teamCanonical } = {}) {
      return {
        name: 'team',
        params: { teamCanonical },
      }
    },
    async onDelete () {
      const { toDelete } = this
      await this.BULK_DELETE({ keyPath: 'teams', toDelete })
      this.$toggle.showDeleteModal(false)
      this.toDelete = []
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
    },
    setMissingOwners (items) {
      for (const item of items) item.owner = getMissingOwnerObject()
    },
    canGoToMember ({ owner }) {
      return !hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('GetOrgMember', owner?.username)
    },
    canUpdateOwner ({ canonical, owner }) {
      return hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('UpdateTeam', canonical)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:Teams',
        areYouSure: 'Are you really sure you want to delete this @:team? | Are you sure you want to delete the following @:teams?',
        confirmDeleteTitle: '@:forms.btnDelete @:team | @:forms.btnDelete @:teams',
        createTeamBtn: 'Create a new team',
        defaultRoles: 'Default role',
        deleteTeamBtn: '@:forms.btnDelete {nb} @:team | @:forms.btnDelete {nb} @:teams',
      },
      es: {
        title: '@:Teams',
        areYouSure: '¿Está realmente seguro de que desea eliminar este @:team? | ¿Está seguro de que desea eliminar los siguientes @:teams?',
        confirmDeleteTitle: '@:forms.btnDelete @:team | @:forms.btnDelete @:teams',
        createTeamBtn: 'Crear un nuevo @:team',
        defaultRoles: 'Rol predeterminado',
        deleteTeamBtn: '@:forms.btnDelete {nb} @:team | @:forms.btnDelete {nb} @:teams',
      },
      fr: {
        title: '@:Teams',
        areYouSure: 'Êtes-vous vraiment sûr de vouloir supprimer cette @:team ? | Êtes-vous sûr de vouloir supprimer les @:teams suivantes ?',
        confirmDeleteTitle: '@:forms.btnDelete une @:team | @:forms.btnDelete des @:teams',
        createTeamBtn: 'Créer une nouvelle @:team',
        defaultRoles: 'Rôle par défaut',
        deleteTeamBtn: '@:forms.btnDelete {nb} @:team | @:forms.btnDelete {nb} @:teams',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .member {
    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .ellipsis {
        align-self: flex-end;
        margin-left: 4px;
        color: cy-get-color("accent");
        font-weight: $font-weight-bolder;
      }
    }

    &__count {
      margin-right: 8px;
    }
  }
</style>
